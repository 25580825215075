
const FILTER_STATUS = {
  ALL: 99,
  NOT_SENDING: 0, // 未送信
  WAITING_CONFIRM: 1, // 確認中
  CONFIRMED: 2, // 確認済
  CANCEL: 3, // 取消
  FAILER: 4, // 送信失敗
}

const SEND_STATUS = {
  NOT_SENDING: 0, // 未送信
  FAILER: 10, // 送信失敗
  SUCCESS: 15, // 送信成功
  CANCEL: 20, // 送信取消
}
const REPLY_STATUS = {
  NO_REPLY_REQUIRED: { VALUE: 0, TEXT: '返信不要', COLOR: 'gray--text', },
  NO_REPLY: { VALUE: 1, TEXT: '返信未完了', COLOR: 'orange lighten-1 white--text', },
  COMPLETED_REPLY: { VALUE: 2, TEXT: '返信完了', COLOR: 'primary lighten-1 gray--text', },
}

const REPLY_CONFIRM_STATUS = {
  ALL: 99, // すべて
  NO_REPLY_REQUIRED: 0, // 未返信
  COMPLETED_REPLY: 1, // 返信済
}

const REPLY_TYPE = {
  NO_REPLY_REQUIRED: { VALUE: 0, TEXT: '返信不要', TEXT_CUSTOMER: '返信不要' },
  CONTRACT_GROUP: { VALUE: 1, TEXT: '代表者返信', TEXT_CUSTOMER: '代表者返信' },
  CUSTOMER: { VALUE: 2, TEXT: '全員返信', TEXT_CUSTOMER: '全員返信' },
}

const REPLY_TYPE_SELECT_LIST = [
  { replyable_type: 0, value: '返信不要' },
  { replyable_type: 1, value: '要返信（代表者返信）' },
  { replyable_type: 2, value: '要返信（全員返信）' }
]

export default {
  ALL_FILTER_STATUS: FILTER_STATUS.ALL,
  FILTER: [
    { text: 'すべて', value: FILTER_STATUS.ALL },
    { text: '未送信', value: FILTER_STATUS.NOT_SENDING },
    { text: '確認中', value: FILTER_STATUS.WAITING_CONFIRM },
    { text: '確認済', value: FILTER_STATUS.CONFIRMED },
    { text: '取消', value: FILTER_STATUS.CANCEL },
  ],
  FILTER_STATUS,
  SEND_STATUS,
  REPLY_STATUS,

  // step - left menu
  EDIT_STEPS: {
    TITLE: {
      TITLE: 'タイトル',
      STEP: 0,
    },
    TYPE: {
      TITLE: '書類タイプ',
      STEP: 1,
    },
    FILES: {
      TITLE: '配布書類',
      STEP: 2,
    },
    SENDER: {
      TITLE: '送信先',
      STEP: 3,
    },
    CONFIRM: {
      TITLE: '確認',
      STEP: 4,
    },
  },

  // MS-3-6-0-0-0-4 配布書類セット確認状況詳細ダイアログ フィルタ
  CONFIRM_FILTER: [
    { text: '確認済', value: FILTER_STATUS.CONFIRMED },
    { text: '確認中', value: FILTER_STATUS.WAITING_CONFIRM },
    { text: '送信失敗', value: FILTER_STATUS.FAILER },
    { text: '未送信', value: FILTER_STATUS.NOT_SENDING },
  ],
  MAX_SELECT_COUNT: 200,
  MAX_FILE_COUNT: 100,

  CHIP_INFO: {
    NOT_SENDING: {
      color: 'greay lighten-1 gray--text',
      label: '未送信',
      textColor: 'gray',
    },
    CANCEL: {
      color: 'greay lighten-1 gray--text',
      label: '未送信(取消)',
      textColor: 'gray',
    },
    FAILER: {
      color: 'danger lighten-2 white--text',
      label: '送信失敗',
      textColor: 'gray',
    },
    WAITING_CONFIRM: {
      color: 'orange lighten-1 white--text',
      label: '確認中',
      textColor: 'gray',
    },
    CONFIRMED: {
      color: 'primary lighten-1 gray--text',
      label: '確認済',
      textColor: 'gray',
    }
  },

  REPLY_CONFIRM_STATUS,
  REPLY_STATUS_FILTER: [
    { text: 'すべて', value: REPLY_CONFIRM_STATUS.ALL },
    { text: '未返信', value: REPLY_CONFIRM_STATUS.NO_REPLY_REQUIRED },
    { text: '返信済', value: REPLY_CONFIRM_STATUS.COMPLETED_REPLY },
  ],

  REPLY_TYPE,
  REPLY_TYPE_SELECT_LIST,
  REPLY_DEFAULT_VALUE: REPLY_TYPE.NO_REPLY_REQUIRED.VALUE, // 返信不要
  CUSTOMER_FILE_UPLOAD_WARNING_TEXT_LIST: [
    '同一契約内の代表者として返信するため、他の契約者にも共有されます。',
    '他の契約者が返信した場合は、ファイルが上書きされます。'
  ],
  ALL_LOT_NUMBER: '全区画'
}
